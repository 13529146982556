import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { IDrugEditableFields } from 'src/models/drugLibrary'
import {
  IPrescription,
  IPrescriptionCreationBody,
  IPrescriptionDosageUpdateBody,
  IPrescriptionRefillUpdateBody
} from 'src/models/prescription'
import {
  IUser,
  IBlockUserBody,
  IUserNoteBody,
  IUserAddress,
  IDirectDepositData,
  IUserClaim,
  UserClaimStatus,
  IUserLearningModuleResponses,
  IUserBalances,
  ProgramMembershipVerificationStatuses
} from 'src/models/user'
import { logger } from 'src/utils/logger'
import 'src/shared/refreshToken'

import {
  IDin,
  IEntryPointData,
  IProgram,
  IProgramCreateData,
  IProgramEntryCodeData,
  IProgramMembershipClaimData
} from '../models/program'
import { IRemindersConfigOptions } from 'src/models/notifications'
import { IAllCompletedTransactions } from 'src/models/transaction'
import { IS3PutPresignedUrlReturn } from 'src/models/image'
import { IPutUserProgramCertificateData } from 'src/features/userAdmin/hooks/useProgramCertificateForm'
import {
  IPrescriptionVerificationTask,
  IVerificationTaskResponseBody,
  PrescriptionVerificationStatuses,
  VerificationTaskStatus
} from 'src/models/verificationTask'
import { ICommercialCodeData } from 'src/models/tools'
import { IEngagementFormData, IModuleFormData } from 'src/models/module'

const SERVER_URL = process.env.REACT_APP_SERVER
const NON_ADMIN_SERVER_URL = process.env.REACT_APP_SERVER_NON_ADMIN

export async function serverFetch<T = any>(
  url: string,
  params: any
): Promise<AxiosResponse<T>> {
  const headers = {
    'Content-Type': params.contentType ?? 'application/json',
    Accept: 'application/json',
    'Cache-Control': 'no-cache',
    ...params.headers
  }
  let serverAddress = SERVER_URL

  if (params.nonAdmin) {
    serverAddress = NON_ADMIN_SERVER_URL
  }

  const requestParams: AxiosRequestConfig = {
    ...params,
    url: `${serverAddress}${url}`,
    headers
  }

  if (params.body) {
    requestParams.data = params.contentType
      ? params.body
      : JSON.stringify(params.body)
  }

  return axios(requestParams)
}

export const ProgramsApi = {
  listPrograms: async () => {
    try {
      const res = await serverFetch(`/programs`, {
        method: 'GET'
      })
      return res.data
    } catch {
      throw new Error('Programs could not be fetched at this time.')
    }
  },
  listProgram: async (programId: string) => {
    try {
      const res = await serverFetch(`/program/${programId}`, {
        method: 'GET'
      })
      return res.data
    } catch {
      throw new Error('Program could not be fetched at this time.')
    }
  },
  getEntryCodes: async (programId: string) => {
    try {
      const res = await serverFetch(`/program/${programId}/entry-codes`, {
        method: 'GET'
      })
      return res.data
    } catch {
      throw new Error('Entry codes could not be fetched at this time.')
    }
  },
  createEntryCode: async (programId: string, data: IProgramEntryCodeData) => {
    try {
      return await serverFetch(`/program/${programId}/entry-code`, {
        method: 'POST',
        body: data
      })
    } catch {
      throw new Error('Entry code could not be created at this time.')
    }
  },
  updateEntryCode: async (
    programId: string,
    codeId: string,
    data: IProgramEntryCodeData
  ) => {
    try {
      return await serverFetch(`/program/${programId}/entry-code/${codeId}`, {
        method: 'PUT',
        body: data
      })
    } catch {
      throw new Error('Entry code could not be updated at this time.')
    }
  },
  generateEntryCode: async (programId: string, num = 1) => {
    try {
      const res = await serverFetch(
        `/program/${programId}/generate-entry-code?num=${num}`,
        {
          method: 'POST'
        }
      )
      return res.data
    } catch {
      throw new Error(
        `Unable to generate an entry code for this program at this time.`
      )
    }
  },
  createProgram: async (data: IProgramCreateData) => {
    try {
      return await serverFetch<string>(`/program`, {
        method: 'POST',
        body: {
          ...data,
          endDate: new Date(data.endDate).toISOString()
        }
      })
    } catch (e: unknown) {
      throw new Error(`This program could not be created at this time.: ${e}`)
    }
  },
  updateProgram: async (
    programId: string,
    data: Partial<Omit<IProgram, 'accessCodes' | 'endDate'>>
  ) => {
    return await serverFetch(`/program/${programId}`, {
      method: 'PUT',
      body: data
    })
  },
  deleteProgram: async (programId: string) => {
    try {
      return await serverFetch(`/program/${programId}`, {
        method: 'DELETE'
      })
    } catch {
      throw new Error('This program could not be deleted at this time.')
    }
  },
  getProgramEngagements: async (programId: string) => {
    try {
      const res = await serverFetch(`/program/${programId}/engagements`, {
        method: 'GET'
      })
      return res.data
    } catch {
      throw new Error('Program engagements could not be fetched at this time.')
    }
  },
  getProgramEngagementsv2: async (programId: string) => {
    try {
      const res = await serverFetch(`/program/${programId}/engagements-v2`, {
        method: 'GET'
      })
      return res.data
    } catch {
      throw new Error('Program engagements could not be fetched at this time.')
    }
  },
  updateProgramEngagements: async (
    programId: string,
    engagements: IEngagementFormData[]
  ) => {
    try {
      const res = await serverFetch(`/program/${programId}/engagements`, {
        method: 'PUT',
        body: engagements
      })
      return res.data
    } catch {
      throw new Error('Program engagements could not be updated at this time.')
    }
  },
  updateProgramEngagementsv2: async (
    programId: string,
    engagements: IEngagementFormData[],
    version: number
  ) => {
    try {
      const res = await serverFetch(`/program/${programId}/engagements-v2`, {
        method: 'PUT',
        body: { engagements, version }
      })
      return res.data
    } catch {
      throw new Error('Program engagements could not be updated at this time.')
    }
  },
  getAttachedLearningModules: async (programId: string) => {
    try {
      const res = await serverFetch(`/programs/${programId}/learning-modules`, {
        method: 'GET'
      })
      return res.data
    } catch {
      throw new Error('Learning Modules could not be fetched at this time')
    }
  },
  getWaitList: async (programId: string) => {
    try {
      const res = await serverFetch(`/program/${programId}/wait-list`, {
        method: 'GET'
      })
      return res.data
    } catch {
      throw new Error('Wait list could not be fetched at this time')
    }
  },
  admitUserToProgram: async (programId: string, userId: string) => {
    try {
      const res = await serverFetch(
        `/program/${programId}/user/${userId}/admit`,
        {
          method: 'POST'
        }
      )
      return res.data
    } catch {
      throw new Error('User could not be admitted to program at this time')
    }
  },
  searchPrograms: async (query: string) => {
    try {
      const res = await serverFetch(`/program/search?${query}`, {
        method: 'GET'
      })
      return res.data
    } catch {
      throw new Error('Programs could not be fetched at this time.')
    }
  },
  attachLearningModule: async (
    programId: string,
    moduleId: string,
    body: any
  ) => {
    try {
      const res = await serverFetch(
        `/programs/${programId}/learning-modules/${moduleId}`,
        {
          method: 'POST',
          body
        }
      )
      return res.data
    } catch {
      throw new Error('Learning module could not be attached at this time')
    }
  },
  updateAttachedLearningModule: async (
    programId: string,
    moduleId: string,
    body: any
  ) => {
    try {
      const res = await serverFetch(
        `/programs/${programId}/learning-modules/${moduleId}`,
        {
          method: 'PUT',
          body
        }
      )
      return res.data
    } catch {
      throw new Error('Learning module could not be updated at this time')
    }
  },
  unlinkAttachedLearningModule: async (programId: string, moduleId: string) => {
    try {
      const res = await serverFetch(
        `/programs/${programId}/learning-modules/${moduleId}`,
        {
          method: 'DELETE'
        }
      )
      return res.data
    } catch {
      throw new Error('Learning module could not be attached at this time')
    }
  },
  updateProgramEntryPoint: async (programId: string, data: IEntryPointData) => {
    try {
      const res = await serverFetch(`/program/${programId}/entry-point`, {
        method: 'PUT',
        body: data
      })
      return res.data
    } catch {
      throw new Error('Entry point could not be updated at this time')
    }
  },
  deleteProgramEntryPoint: async (programId: string) => {
    try {
      const res = await serverFetch(`/program/${programId}/entry-point`, {
        method: 'DELETE'
      })
      return res.data
    } catch {
      throw new Error('Entry point could not be deleted at this time')
    }
  },
  createProgramEntryPoint: async (programId: string, data: IEntryPointData) => {
    try {
      const res = await serverFetch(`/program/${programId}/entry-point`, {
        method: 'POST',
        body: data
      })
      return res.data
    } catch {
      throw new Error('Entry point could not be created at this time')
    }
  },
  addDins: async (programId: string, data: IDin[]) => {
    try {
      const res = await serverFetch(`/program/${programId}/dins`, {
        method: 'POST',
        body: {
          dins: data
        }
      })

      return res.data
    } catch {
      throw new Error('Unable to add DINs to program')
    }
  },
  removeDins: async (programId: string, data: IDin[]) => {
    try {
      const res = await serverFetch(`/program/${programId}/dins`, {
        method: 'DELETE',
        body: {
          dins: data
        }
      })

      return res.data
    } catch {
      throw new Error('Unable to remove DINs from program')
    }
  },
  updateEndDate: async (programId: string, date: Date) => {
    try {
      const res = await serverFetch(`/program/${programId}/end-date`, {
        method: 'PUT',
        body: {
          newDate: date.toISOString()
        }
      })

      return res.data
    } catch {
      throw new Error('Unable to update program end date')
    }
  }
}

export const UsersApi = {
  getUser: async (userId: string): Promise<IUser> => {
    try {
      const res = await serverFetch(`/users/${userId}`, {
        method: 'GET'
      })
      return res.data
    } catch {
      throw new Error('This user could not be fetched at this time.')
    }
  },
  getUserBalances: async (userId: string): Promise<IUserBalances> => {
    try {
      const res = await serverFetch(`/users/${userId}/lifetime-balances`, {
        method: 'GET'
      })
      return res.data
    } catch {
      throw new Error('This users balances could not be fetched at this time.')
    }
  },
  blockUser: async (userId: string, body: IBlockUserBody): Promise<void> => {
    try {
      const res = await serverFetch(`/users/${userId}/block`, {
        method: 'PUT',
        body
      })
      return res.data
    } catch {
      throw new Error('This user could not be blocked at this time.')
    }
  },
  anonymizeUser: async (userId: string): Promise<void> => {
    try {
      const res = await serverFetch(`/users/${userId}`, {
        method: 'DELETE',
        body: {}
      })
      return res.data
    } catch {
      throw new Error('This user could not be deleted at this time.')
    }
  },
  purgeUser: async (userId: string): Promise<void> => {
    try {
      const res = await serverFetch(`/users/${userId}/purge`, {
        method: 'DELETE',
        body: {}
      })
      return res.data
    } catch {
      throw new Error('This user could not be purged at this time.')
    }
  },
  purgeAllUserTraces: async (userId: string): Promise<void> => {
    try {
      const res = await serverFetch(`/users/${userId}/purge-all-traces`, {
        method: 'DELETE',
        body: {}
      })
      return res.data
    } catch {
      throw new Error('This user could not be erased at this time.')
    }
  },
  unblockUser: async (userId: string): Promise<void> => {
    try {
      const res = await serverFetch(`/users/${userId}/unblock`, {
        method: 'PUT'
      })
      return res.data
    } catch {
      throw new Error('This user could not be unblocked at this time.')
    }
  },
  updateUser: async (userId: string, body: IUser): Promise<IUser> => {
    try {
      const res = await serverFetch(`/users/${userId}`, {
        method: 'PUT',
        body
      })
      return res.data
    } catch {
      throw new Error('This user could not be fetched at this time.')
    }
  },
  listUsers: async () => {
    try {
      const res = await serverFetch(`/users`, {
        method: 'GET'
      })
      return res.data
    } catch {
      throw new Error('Users could not be fetched at this time.')
    }
  },
  createUserNote: async (userId: string, body: IUserNoteBody) => {
    try {
      const res = await serverFetch(`/users/${userId}/notes`, {
        method: 'POST',
        body
      })
      return res.data
    } catch (err) {
      logger.error('User note could not be created at this time.', err)
    }
  },
  listUserNotes: async (userId: string) => {
    try {
      const res = await serverFetch(`/users/${userId}/notes`, {
        method: 'GET'
      })
      return res.data
    } catch {
      throw new Error('User notes could not be fetched at this time.')
    }
  },
  requestPhysicalCard: async (userId: string, body: IUserAddress) => {
    try {
      const res = await serverFetch(`/users/${userId}/card`, {
        method: 'PUT',
        body
      })
      return res.data
    } catch {
      throw new Error(
        'Request for physical card could not be submitted at this time.'
      )
    }
  },
  listPrograms: async (userId: string) => {
    try {
      const res = await serverFetch(`/users/${userId}/program-memberships`, {
        method: 'GET'
      })
      return res.data
    } catch {
      throw new Error(
        'Programs for this user could not be fetched at this time.'
      )
    }
  },
  listActivities: async (userId: string) => {
    try {
      const res = await serverFetch(`/users/${userId}/user-related-actions`, {
        method: 'GET'
      })
      return res.data
    } catch {
      throw new Error(`User related actions could not be fetched at this time.`)
    }
  },
  listTransactions: async (userId: string) => {
    try {
      const res = await serverFetch(`/users/${userId}/transactions`, {
        method: 'GET'
      })
      return res.data
    } catch {
      throw new Error(`Transactions could not be fetched at this time.`)
    }
  },
  listTransactionsForProgram: async (userId: string, programId: string) => {
    try {
      const res = await serverFetch(
        `/users/${userId}/program-memberships/${programId}/transactions`,
        {
          method: 'GET'
        }
      )
      return res.data
    } catch {
      throw new Error(`Transactions could not be fetched at this time.`)
    }
  },
  deleteProgramMembership: async (
    programId: string,
    userId: string,
    notes: string,
    preventReEnrolment: boolean = true
  ) => {
    try {
      const res = await serverFetch(
        `/users/${userId}/program-memberships/${programId}`,
        {
          method: 'DELETE',
          body: {
            notes,
            preventReEnrolment
          }
        }
      )
      return res.data
    } catch {
      throw new Error(`Program membership could not be deleted at this time.`)
    }
  },
  fetchCertificateForProgram: async (userId: string, programId: string) => {
    try {
      const res = await serverFetch(
        `/users/${userId}/program-memberships/${programId}/certificate`,
        {
          method: 'GET'
        }
      )
      return res.data
    } catch {
      throw new Error(
        'Certificate for users program could not be fetched at this time.'
      )
    }
  },
  updateCertificateForProgram: async (
    userId: string,
    programId: string,
    data: IPutUserProgramCertificateData
  ) => {
    try {
      const res = await serverFetch(
        `/users/${userId}/program-memberships/${programId}/certificate`,
        {
          method: 'PUT',
          body: data
        }
      )

      return res.data
    } catch {
      throw new Error('Unable to update certificate at this time')
    }
  },
  deleteCertificateForProgram: async (userId: string, programId: string) => {
    try {
      const res = await serverFetch(
        `/users/${userId}/program-memberships/${programId}/certificate`,
        {
          method: 'DELETE'
        }
      )

      return res.data
    } catch {
      throw new Error('Unable to delete certificate at this time')
    }
  },
  listCompletedTasksForProgram: async (
    userId: string,
    programId: string
  ): Promise<IAllCompletedTransactions[]> => {
    try {
      const res = await serverFetch(
        `/users/${userId}/program-memberships/${programId}/completed-tasks`,
        {
          method: 'GET'
        }
      )
      return res.data
    } catch {
      throw new Error(
        `Completed tasks for a program membership could not be fetched at this time.`
      )
    }
  },
  addClaimToProgramMembership: async (
    userId: string,
    programId: string,
    data: IProgramMembershipClaimData
  ) => {
    try {
      const res = await serverFetch(
        `/users/${userId}/program-memberships/${programId}/transactions/claim`,
        {
          method: 'POST',
          body: data
        }
      )

      return res.data
    } catch {
      throw new Error('Unable to create a claim at this time')
    }
  },
  getLearningModuleResults: async (
    userId: string,
    programId: string,
    learningModuleId: string
  ): Promise<IUserLearningModuleResponses[]> => {
    try {
      const res = await serverFetch(
        `/users/${userId}/program-memberships/${programId}/learning-module/${learningModuleId}`,
        {
          method: 'GET'
        }
      )

      return res.data
    } catch {
      throw new Error('Unable to fetch learning module results at this time')
    }
  },
  addDirectDeposit: async (userId: string, data: IDirectDepositData) => {
    try {
      const res = await serverFetch(`/users/${userId}/direct-deposit-info`, {
        method: 'POST',
        body: data
      })

      return res.data
    } catch {
      throw new Error('Unable to add a direct deposit')
    }
  },
  updateDirectDeposit: async (
    userId: string,
    directDepositId: string,
    data: IDirectDepositData
  ) => {
    try {
      const res = await serverFetch(
        `/users/${userId}/direct-deposit-info/${directDepositId}`,
        {
          method: 'PUT',
          body: data
        }
      )

      return res.data
    } catch {
      throw new Error('Unable to update direct deposit')
    }
  },
  deleteDirectDeposit: async (userId: string, directDepositId: string) => {
    try {
      const res = await serverFetch(
        `/users/${userId}/direct-deposit-info/${directDepositId}`,
        {
          method: 'DELETE'
        }
      )

      return res.data
    } catch {
      throw new Error('Unable to remove direct deposit')
    }
  },
  fetchDirectDeposits: async (userId: string) => {
    try {
      const res = await serverFetch(`/users/${userId}/direct-deposit-info`, {
        method: 'GET'
      })

      return res.data
    } catch {
      throw new Error('Unable to fetch direct deposits')
    }
  },
  fetchClaims: async (userId: string): Promise<IUserClaim[]> => {
    try {
      const res = await serverFetch(`/users/${userId}/claims`, {
        method: 'GET'
      })

      return res.data
    } catch {
      throw new Error('Could not fetch user claims at this time')
    }
  },
  updateClaimStatus: async (
    userId: string,
    claimId: string,
    status: UserClaimStatus
  ) => {
    try {
      const res = await serverFetch(
        `/users/${userId}/claims/${claimId}/status`,
        {
          method: 'PUT',
          body: {
            status
          }
        }
      )

      return res.data
    } catch {
      throw new Error('Could not update claim status at this time')
    }
  }
}

export const PrescriptionsApi = {
  updatePrescriptionDosage: async (
    userId: string,
    prescriptionId: string,
    prescription: IPrescriptionDosageUpdateBody
  ): Promise<IPrescription> => {
    try {
      const res = await serverFetch(
        `/users/${userId}/prescriptions/${prescriptionId}/dosage`,
        {
          method: 'PUT',
          body: prescription
        }
      )
      return res.data
    } catch {
      throw new Error('The users prescription dosage could not be updated')
    }
  },
  updatePrescriptionRefill: async (
    userId: string,
    prescriptionId: string,
    body: IPrescriptionRefillUpdateBody
  ): Promise<IPrescription> => {
    try {
      const res = await serverFetch(
        `/users/${userId}/prescriptions/${prescriptionId}/fill`,
        {
          method: 'PUT',
          body
        }
      )

      return res.data
    } catch {
      throw new Error('The users prescription refill could not be updated')
    }
  },
  createPrescription: async (
    userId: string,
    body: IPrescriptionCreationBody
  ): Promise<IPrescription> => {
    try {
      const res = await serverFetch(`/users/${userId}/prescriptions`, {
        method: 'POST',
        body
      })
      return res.data
    } catch {
      throw new Error('The user prescriptions could not be created')
    }
  },
  addPrescriptionProgramMembership: async (
    userId: string,
    prescriptionId: string,
    programId: string
  ): Promise<IPrescription> => {
    try {
      const res = await serverFetch(
        `/users/${userId}/prescriptions/${prescriptionId}/programs/${programId}`,
        {
          method: 'PUT'
        }
      )

      return res.data
    } catch {
      throw new Error('Unable to add a program membership to this prescription')
    }
  },
  deletePrescription: async (
    userId: string,
    prescriptionId: string
  ): Promise<void> => {
    try {
      const res = await serverFetch(
        `/users/${userId}/prescriptions/${prescriptionId}`,
        {
          method: 'DELETE'
        }
      )
      return res.data
    } catch {
      throw new Error('The user prescriptions could not be created')
    }
  },
  deletePrescriptionMembership: async (
    userId: string,
    prescriptionId: string,
    programId: string
  ): Promise<IPrescription> => {
    try {
      const res = await serverFetch(
        `/users/${userId}/prescriptions/${prescriptionId}/programs/${programId}`,
        {
          method: 'DELETE'
        }
      )

      return res.data
    } catch {
      throw new Error(
        'Unable to delete prescriptions program membership at this time'
      )
    }
  },
  listUserPrescriptions: async (userId: string): Promise<IPrescription[]> => {
    try {
      const res = await serverFetch(`/users/${userId}/prescriptions`, {
        method: 'GET'
      })
      return res.data
    } catch {
      throw new Error(
        'The user prescriptions could not be fetched at this time.'
      )
    }
  },
  listPrescriptionCompatiblePrograms: async (
    userId: string,
    prescriptionId: string
  ): Promise<IProgram[]> => {
    try {
      const res = await serverFetch(
        `/users/${userId}/prescriptions/${prescriptionId}/eligible-programs`,
        {
          method: 'GET'
        }
      )

      return res.data
    } catch {
      throw new Error(
        'Compatible programs for this prescription could not be fetched at this time'
      )
    }
  },
  updatePrescriptionImage: async (
    userId: string,
    prescriptionId: string,
    imageKey: string
  ): Promise<null> => {
    try {
      await serverFetch(
        `/user/${userId}/prescription/${prescriptionId}/image`,
        {
          method: 'PUT',
          body: { imageUrl: imageKey }
        }
      )

      return null
    } catch {
      throw new Error('Unable to update prescription image')
    }
  }
}

export const NotificationsApi = {
  getNotifications: async (userId: string) => {
    try {
      const res = await serverFetch(`/notifications/user/${userId}`, {
        method: 'GET'
      })
      return res.data
    } catch {
      throw new Error('Notifications could not be fetched at this time.')
    }
  },
  getNotificationSettings: async (userId: string) => {
    try {
      const res = await serverFetch(`/notifications/user/${userId}/config`, {
        method: 'GET'
      })
      return res.data
    } catch {
      throw new Error(
        'Notification settings could not be fetched at this time.'
      )
    }
  },
  updateNotificationSettings: async (
    userId: string,
    body: IRemindersConfigOptions
  ) => {
    try {
      const res = await serverFetch(`/notifications/user/${userId}/config`, {
        method: 'PUT',
        body
      })
      return res.data
    } catch {
      throw new Error(
        'Notification settings could not be updated at this time.'
      )
    }
  }
}

export const DinsApi = {
  listDins: async () => {
    try {
      const res = await serverFetch(`/drug-lib`, {
        method: 'GET',
        nonAdmin: true
      })
      return res.data
    } catch {
      throw new Error('Dins could not be fetched at this time.')
    }
  },
  getDin: async (din: string) => {
    try {
      const res = await serverFetch(`/drug-lib/${din}`, {
        method: 'GET',
        nonAdmin: true
      })
      return res.data
    } catch (err) {
      logger.error(`Din ${din} could not be fetched at this time.`, err)
    }
  },
  updateDin: async (din: string, newValues: IDrugEditableFields) => {
    try {
      const res = await serverFetch(`/drug-lib/${din}`, {
        method: 'PUT',
        body: newValues,
        nonAdmin: true
      })
      return res.data
    } catch {
      throw new Error(`Din ${din} could not be updated at this time`)
    }
  },
  searchDins: async (queryString: string) => {
    try {
      const res = await serverFetch(`/drug-lib/search?${queryString}`, {
        method: 'GET',
        nonAdmin: true
      })
      return res.data
    } catch {
      throw new Error('Drug library could not be fetched at this time.')
    }
  },
  searchIndications: async (query: string, limit: number) => {
    try {
      const res = await serverFetch(
        `/drug-lib/indications?name=${query}&limit=${limit}`,
        {
          method: 'GET',
          nonAdmin: true
        }
      )
      return res.data
    } catch {
      throw new Error('Indications could not be fetched at this timed')
    }
  },
  searchSideEffects: async (query: string, limit: number) => {
    try {
      const res = await serverFetch(
        `/drug-lib/side-effects?name=${query}&limit=${limit}`,
        {
          method: 'GET',
          nonAdmin: true
        }
      )
      return res.data
    } catch {
      throw new Error('Side Effects could not be fetched at this timed')
    }
  },
  getIndication: async (id: string) => {
    try {
      const res = await serverFetch(`/drug-lib/indications/${id}`, {
        method: 'GET',
        nonAdmin: true
      })
      return res.data
    } catch {
      throw new Error(`Indication ${id} could not be fetched at this time`)
    }
  },
  updateIndication: async (name: string, id: string) => {
    try {
      const res = await serverFetch(`/drug-lib/indications/${id}`, {
        method: 'PUT',
        nonAdmin: true,
        body: name,
        contentType: 'application/text'
      })
      return res.data
    } catch {
      throw new Error(`Indication ${id} could not be updated at this time`)
    }
  },
  createIndication: async (name: string) => {
    try {
      const res = await serverFetch(`/drug-lib/indications`, {
        method: 'POST',
        nonAdmin: true,
        body: name,
        contentType: 'application/text'
      })
      return res.data
    } catch {
      throw new Error(`Indication could not be created at this time`)
    }
  },
  getSideEffect: async (id: string) => {
    try {
      const res = await serverFetch(`/drug-lib/side-effects/${id}`, {
        method: 'GET',
        nonAdmin: true
      })
      return res.data
    } catch {
      throw new Error(`Side effect ${id} could not be fetched at this time`)
    }
  },
  updateSideEffect: async (name: string, id: string) => {
    try {
      const res = await serverFetch(`/drug-lib/side-effects/${id}`, {
        method: 'PUT',
        nonAdmin: true,
        body: name,
        contentType: 'application/text'
      })
      return res.data
    } catch {
      throw new Error(`Side Effect could not be updated at this time`)
    }
  },
  createSideEffect: async (name: string) => {
    try {
      const res = await serverFetch(`/drug-lib/side-effects`, {
        method: 'POST',
        nonAdmin: true,
        body: name,
        contentType: 'application/text'
      })
      return res.data
    } catch {
      throw new Error(`Side Effect could not be created at this time`)
    }
  }
}

export const LMSApi = {
  getLearningModules: async () => {
    try {
      const res = await serverFetch(`/learning-modules`, {
        method: 'GET'
      })
      return res.data
    } catch {
      throw new Error(`Learning modules could not be fetched at this time`)
    }
  },
  getLearningModule: async (id: string) => {
    try {
      const res = await serverFetch(`/learning-modules/${id}`, {
        method: 'GET'
      })
      return res.data
    } catch {
      throw new Error(`Learning module ${id} could not be fetched at this time`)
    }
  },
  createLearningModule: async (body: any) => {
    try {
      const res = await serverFetch(`/learning-modules`, {
        method: 'POST',
        body
      })
      return res.data
    } catch {
      throw new Error(`Learning module could not be created at this time`)
    }
  },
  updateLearningModule: async (id: string, body: any) => {
    try {
      const res = await serverFetch(`/learning-modules/${id}`, {
        method: 'PUT',
        body
      })
      return res.data
    } catch {
      throw new Error(`Learning module ${id} could not be updated at this time`)
    }
  },
  deleteLearningModule: async (id: string) => {
    try {
      const res = await serverFetch(`/learning-modules/${id}`, {
        method: 'DELETE'
      })
      return res.data
    } catch {
      throw new Error(`Learning module ${id} could not be deleted at this time`)
    }
  },
  draftLearningModule: async (id: string) => {
    try {
      const res = await serverFetch(`/learning-modules/${id}/draft`, {
        method: 'PUT'
      })
      return res.data
    } catch {
      throw new Error(
        `Learning module ${id} could not be set to draft at this time`
      )
    }
  },
  publishLearningModule: async (id: string) => {
    try {
      const res = await serverFetch(`/learning-modules/${id}/publish`, {
        method: 'PUT'
      })
      return res.data
    } catch (e) {
      console.error(e)
      throw new Error(
        `Learning module ${id} could not be set to publish at this time`
      )
    }
  }
}

export const ImagesApi = {
  getImagePresignedUrl: async (
    imageKey: string
  ): Promise<{ allowableMethod: 'PUT'; presignedUrl: string }> => {
    try {
      const res = await serverFetch(`/image/url/get`, {
        method: 'PUT',
        body: {
          objectKey: imageKey
        }
      })

      return res.data
    } catch {
      throw new Error(`Could not return get url for image key ${imageKey}`)
    }
  },
  putImagePresignedUrl: async (
    imageKey: string
  ): Promise<IS3PutPresignedUrlReturn> => {
    try {
      const res = await serverFetch(`/image/url/put`, {
        method: 'PUT',
        body: {
          objectKey: imageKey
        }
      })

      return res.data
    } catch {
      throw new Error(`Could not return put url for image key ${imageKey}`)
    }
  },
  uploadImageToPresignedUrl: async (presignedUrl: string, blob: Blob) => {
    try {
      const res = await axios.put(presignedUrl, blob, {
        transformRequest: (data, headers) => {
          delete headers.Authorization
          return data
        }
      })

      return res.data
    } catch {
      throw new Error(`Unable to upload image to URL: ${presignedUrl}`)
    }
  }
}

export const ClaimsApi = {
  getAllClaims: async (): Promise<IUserClaim[]> => {
    try {
      const res = await serverFetch(`/claims`, {
        method: 'GET'
      })

      return res.data
    } catch {
      throw new Error(`Could not return get claims at this time`)
    }
  }
}

export const VerificationsApi = {
  listVerificationTasks: async (
    status: VerificationTaskStatus,
    offset: number,
    limit: number,
    dateAfter?: Date,
    userId?: string
  ): Promise<IVerificationTaskResponseBody> => {
    try {
      let url = `/verification/tasks?offset=${offset}&limit=${limit}&status=${status}`
      if (dateAfter) url += `&dateAfter=${dateAfter.toISOString()}`
      if (userId) url += `&userId=${userId}`

      const res = await serverFetch(url, {
        method: 'GET'
      })

      return res.data
    } catch {
      throw new Error('Could not return get verification tasks at this time')
    }
  },
  updateProgramVerificationStatus: async (
    userId: string,
    programId: string,
    status: ProgramMembershipVerificationStatuses
  ) => {
    try {
      const res = await serverFetch(
        `/verification/user/${userId}/program/${programId}`,
        {
          method: 'PUT',
          body: {
            status
          }
        }
      )

      return res.data
    } catch {
      throw new Error(
        'Could not update program verification status at this time'
      )
    }
  },
  updatePrescriptionVerificationStatus: async (
    userId: string,
    prescriptionId: string,
    status: PrescriptionVerificationStatuses,
    customRejectionMsg?: string
  ): Promise<IPrescriptionVerificationTask> => {
    try {
      const res = await serverFetch(
        `/verification/user/${userId}/prescription/${prescriptionId}`,
        {
          method: 'PUT',
          body: {
            status,
            customRejectionMsg
          }
        }
      )

      return res.data
    } catch {
      throw new Error(
        'Could not update prescription verification status at this time'
      )
    }
  },
  getTaskAuditLogs: async (userId: string, programId: string) => {
    try {
      const res = await serverFetch(
        `/verification/user/${userId}/program/${programId}/audit-logs`,
        {
          method: 'GET'
        }
      )

      return res.data
    } catch {
      throw new Error('Could not get task audit logs at this time')
    }
  }
}

export const BatchPaymentFileApi = {
  getBatchPaymentFiles: async () => {
    try {
      const res = await serverFetch(`/batch-payment-files`, {
        method: 'GET'
      })

      return res.data
    } catch {
      throw new Error('Could not get batch payment files at this time')
    }
  },
  getFileBinary: async (fileId: string) => {
    try {
      const res = await serverFetch(`/batch-payment-file/${fileId}`, {
        method: 'GET',
        responseType: 'blob'
      })

      return res.data
    } catch {
      throw new Error('Could not get batch payment file binary at this time')
    }
  },
  markPaid: async (fileId: string) => {
    try {
      const res = await serverFetch(`/batch-payment-file/${fileId}/paid`, {
        method: 'PUT'
      })

      return res.data
    } catch {
      throw new Error('Could not mark batch payment file as paid at this time')
    }
  },
  generate: async (after: string) => {
    try {
      const res = await serverFetch(
        `/batch-payment-file/generate?after=${after}`,
        {
          method: 'POST',
          body: {
            after
          }
        }
      )

      return res.data
    } catch {
      throw new Error('Could not generate batch payment file at this time')
    }
  },
  uploadFailedTransactions: async (file: File) => {
    try {
      const body = new FormData()
      body.append('file', file)

      const res = await serverFetch(`/failed-transactions`, {
        method: 'POST',
        contentType: 'multipart/form-data',
        body
      })

      return res.data
    } catch {
      throw new Error('Could not upload failed transactions at this time')
    }
  }
}

export const CommercialCodesApi = {
  getCommercialCodes: async () => {
    try {
      const res = await serverFetch(`/commercial-referral-codes`, {
        method: 'GET'
      })

      return res.data
    } catch {
      throw new Error('Could not get commercial codes at this time')
    }
  },
  createCommercialCode: async (body: ICommercialCodeData) => {
    try {
      const res = await serverFetch(`/commercial-referral-code`, {
        method: 'POST',
        body
      })

      return res.data
    } catch {
      throw new Error('Could not create commercial code at this time')
    }
  },
  updateCommercialCode: async (code: string, body: ICommercialCodeData) => {
    try {
      const res = await serverFetch(`/commercial-referral-code/${code}`, {
        method: 'PUT',
        body
      })

      return res.data
    } catch {
      throw new Error('Could not update commercial code at this time')
    }
  },
  deleteCommercialCode: async (code: string) => {
    try {
      const res = await serverFetch(`/commercial-referral-code/${code}`, {
        method: 'DELETE'
      })

      return res.data
    } catch {
      throw new Error('Could not delete commercial code at this time')
    }
  }
}

export const ModulesApi = {
  getModules: async () => {
    try {
      const res = await serverFetch(`/modules`, {
        method: 'GET'
      })

      return res.data
    } catch {
      throw new Error('Could not get modules at this time')
    }
  },
  getModule: async (moduleId: string) => {
    try {
      const res = await serverFetch(`/module/${moduleId}`, {
        method: 'GET'
      })

      return res.data
    } catch {
      throw new Error('Could not get module at this time')
    }
  },
  getModulesForProgram: async (programId: string) => {
    try {
      const res = await serverFetch(`/program/${programId}/modules`, {
        method: 'GET'
      })

      return res.data
    } catch {
      throw new Error('Could not get modules for program at this time')
    }
  },
  putModulesForProgram: async (programId: string, body: string[]) => {
    try {
      const res = await serverFetch(`/program/${programId}/modules`, {
        method: 'PUT',
        body: { modules: body }
      })

      return res.data
    } catch (e) {
      throw new Error(`Could not put modules for program at this time: ${e}`)
    }
  },
  createModule: async (body: IModuleFormData) => {
    try {
      const res = await serverFetch<string>(`/module`, {
        method: 'POST',
        body
      })

      return res.data
    } catch {
      throw new Error('Could not create module at this time')
    }
  },
  putModule: async (moduleId: string, body: IModuleFormData) => {
    try {
      const res = await serverFetch(`/module/${moduleId}`, {
        method: 'PUT',
        body
      })

      return res.data
    } catch {
      throw new Error('Could not update module at this time')
    }
  }
}
