import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'
import { TextField } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import React from 'react'
import { IEngagement } from 'src/models/module'
import styled from 'styled-components'
import ModuleItem from './ModuleItem'

const Container = styled.div`
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-bottom: 10px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

const SubHeader = styled.div`
  justify-content: space-between;
  display: flex;
  width: 100%;
  margin-bottom: 10px;
`

const StyledTextField = styled(TextField)`
  margin: 5px;
`

interface IEngagementList {
  engagement: IEngagement
  index: number
  isLast?: boolean
  onDaysChange: (days: number) => void
  onFundsChange: (funds: number) => void
  onDelete: () => void
}

const EngagementList = ({
  engagement,
  index,
  isLast,
  onDaysChange,
  onFundsChange,
  onDelete
}: IEngagementList) => {
  const isDisabled = React.useMemo(() => {
    if (!engagement.assignedEngagementStats) return false
    if (engagement.assignedEngagementStats?.unlocked > 0) return true
    return false
  }, [engagement])

  /**
   * ----- Hook Initialization -----
   */

  const { isOver, setNodeRef } = useSortable({
    id: index,
    disabled: isDisabled
  })

  /**
   * ----- Variables -----
   */

  const style = {
    color: isOver && !isDisabled ? 'green' : undefined
  }

  /**
   * ----- Render -----
   */

  return (
    <SortableContext
      items={engagement.modules.map((module) => module.moduleId)}
      strategy={verticalListSortingStrategy}
      id={index.toString()}
      disabled={isDisabled}
    >
      <Container ref={setNodeRef} style={style}>
        <Header>
          <h4>Engagement {index + 1}</h4>{' '}
          <p>{engagement.assignedEngagementStats?.unlocked || 0} User(s)</p>
        </Header>
        <SubHeader>
          {!isLast ? (
            <StyledTextField
              required
              label="Days till next"
              defaultValue={engagement.daysUntilNext || 0}
              type="number"
              size="small"
              variant="outlined"
              onChange={(e) => onDaysChange(parseInt(e.target.value))}
              InputProps={{ inputProps: { min: 0 } }}
            />
          ) : (
            <div></div>
          )}
          <StyledTextField
            required
            label="Funds ($)"
            defaultValue={engagement.funds.amount || 0}
            type="number"
            size="small"
            variant="outlined"
            onChange={(e) => onFundsChange(parseInt(e.target.value))}
            InputProps={{ inputProps: { min: 0 } }}
          />
          {!isDisabled && (
            <Delete
              style={{ margin: '10px', cursor: 'pointer' }}
              onClick={onDelete}
            />
          )}
        </SubHeader>

        {engagement.modules.map((module) => (
          <ModuleItem
            disabled={isDisabled}
            key={module.moduleId}
            module={module}
            engagementIndex={index}
          />
        ))}
      </Container>
    </SortableContext>
  )
}

export default EngagementList
