import { Node } from '@xyflow/react'
import { LanguageMap, UUID } from './misc'
import { IAmounts } from './program'

export interface IModule {
  moduleId: UUID
  firstTask: UUID
  title: LanguageMap
  description?: LanguageMap
  version: number
  coverImageUrl?: string
  tasks: Record<UUID, ModuleTask>
  estimatedCompletionTime?: number
  supportedLanguages: ('en' | 'fr')[]
}

export enum ModuleTaskType {
  SingleSelectQuestion = 'SingleSelectQuestion',
  MultiSelectQuestion = 'MultiSelectQuestion',
  Message = 'Message',
  FreeFormResponse = 'FreeFormResponse'
}

interface IBaseModuleTask {
  taskId: UUID
  text: LanguageMap
  position: { x: number; y: number }
}

export interface ISingleSelectQuestion extends IBaseModuleTask {
  type: ModuleTaskType.SingleSelectQuestion
  selectableOptions: IAnswerOption[]
  // Record<answerOptionId, taskId>
  navigationMap: Record<UUID, UUID>
  correctAnswer?: UUID
}

export interface IMultiSelectQuestion extends IBaseModuleTask {
  type: ModuleTaskType.MultiSelectQuestion
  followUpTask?: UUID
  selectableOptions: IAnswerOption[]
}

export interface IAnswerOption {
  selectionId: UUID
  text: LanguageMap
}

export interface IMessage extends IBaseModuleTask {
  type: ModuleTaskType.Message
  followUpTask?: UUID
}

export interface IFreeFormResponse extends IBaseModuleTask {
  type: ModuleTaskType.FreeFormResponse
  followUpTask?: UUID
}

export type ModuleTask =
  | ISingleSelectQuestion
  | IMultiSelectQuestion
  | IMessage
  | IFreeFormResponse

export type ModuleTaskNode = Node<ModuleTask & Record<string, unknown>>

export interface IModuleFormData {
  title: LanguageMap
  description?: LanguageMap
  coverImageUrl?: string
  estimatedCompletionTime?: number
  tasks: Record<UUID, ModuleTask>
  supportedLanguages: ('en' | 'fr')[]
  firstTask?: UUID
}

export interface IModuleEssential {
  moduleId: string
  title: string
}

export interface IEngagement {
  assignedEngagementStats?: {
    unlocked: number
  }
  funds: IAmounts
  modules: IModuleEssential[]
  daysUntilNext?: number
}

export interface IEngagementFormData {
  funds: IAmounts
  modules: UUID[]
  daysUntilNext?: number
}
